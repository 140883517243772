import React, { useEffect, useState, lazy } from 'react';
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import backgroundImage from '../assets/fall1.webp';
import { IoLocationSharp } from "react-icons/io5";
import { IoMailSharp } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { FaFacebookF, FaInstagram, FaTwitter, FaPinterestP } from 'react-icons/fa'
import { IoIosSend } from "react-icons/io";
import banner8 from '../assets/false2.png';
import banner7 from '../assets/false3.png';
import banner9 from '../assets/false4.png';
import banner10 from '../assets/false5.png';
import banner11 from '../assets/false6.png';
import banner12 from '../assets/false7.png';
import banner13 from '../assets/false8.png';
import banner14 from '../assets/false9.png';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';





function FallCeiling() {
    return (
        <>
            <Header />
            <ModularBanner />
            <ModularSection />
            <Footer />
        </>

    )
}
function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false); // To toggle Category dropdown
  
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
  
    const toggleCategory = () => {
        setCategoryOpen(!categoryOpen); // Toggle category dropdown
    };
    return (
        <header className="bg-white shadow-md py-5">
            <div className="mx-auto flex items-center justify-between py-4 px-5 lg:px-28">
                <div className="w-2/12 max-sm:w-[40vw]">
                    {/* Logo Section */}
                    <img src={logo} alt="Logo" className="h-12  lg:h-16" />
                </div>
  
                {/* Desktop Navigation Section */}
                <nav className="w-10/12 px-20 py-8 bg-black hidden md:flex space-x-6 text-white font-semibold">
                    <Link to="/" className="hover:text-[#8c2c2c]">Home</Link>
                    <Link to="/about" className="hover:text-[#8c2c2c]">About</Link>
  
                    {/* Category with Submenu and Dropdown Icon */}
                    <div className="relative group">
                        <button className="menu-link hover:text-[#8c2c2c] flex items-center">
                            Category
                           
                        </button>
                        <div className="submenu hidden group-hover:block absolute bg-black text-white shadow-lg">
                            <Link to="/modular-kitchen" className="font-sans font-semibold submenu-item text-white hover:bg-black hover:text-white">Modular Kitchen</Link>
                            <Link to="/Wardrobe" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Wardrobe</Link>
                            <Link to="/pooja-room" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Pooja Room</Link>
                            <Link to="/tv-panel" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Tv Panel</Link>
                            <Link to="/wall-panel" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Wall Panel</Link>
                            <Link to="/wall-shelves" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Wall Shelves</Link>
                            <Link to="/show-case" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Show Case</Link>
                            <Link to="/false-ceiling" className="submenu-item text-gray-700 hover:bg-black hover:text-white">False Ceiling</Link>
                            <Link to="/office-interior" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Office Interior</Link>
                        </div>
                    </div>
                    <Link to="/testimonials" className="hover:text-[#8c2c2c]">Testimonials</Link>
                    <Link to="/contact" className="hover:text-[#8c2c2c]">Contact</Link>
  
                    {/* Social Media Links */}
                    <div className="flex-grow"></div>
                    <div className="flex items-center space-x-3">
                        <a href="https://www.facebook.com/elegantkitchencbe" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://www.instagram.com/elegantkitchencbe/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://x.com/Janarth01930131" target="_blank" rel="noopener noreferrer">
                            <FaTwitter className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://www.pinterest.com/elegantkitchenkovai/" target="_blank" rel="noopener noreferrer">
                            <FaPinterestP className="text-white hover:text-[#8c2c2c]" />
                        </a>
                    </div>
                </nav>
  
                {/* Mobile Menu Button */}
                <button
                    onClick={toggleMenu}
                    className="p-2 md:hidden text-black lg:text-white">
                    ☰
                </button>
            </div>
  
            {/* Mobile Navigation */}
            {menuOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-black text-white z-50 flex flex-col items-center justify-center p-4 md:hidden">
                    {/* Close Button */}
                    <button
                        onClick={toggleMenu}
                        className="text-white absolute top-5 right-5 text-3xl"
                    >
                        ✖
                    </button>
                    <nav className="flex flex-col space-y-6 text-xl text-center">
                        <Link to="/" className="hover:text-gray-400" onClick={toggleMenu}>Home</Link>
                        <Link to="/about" className="hover:text-gray-400" onClick={toggleMenu}>About</Link>
  
                        {/* Category Dropdown in Mobile View */}
                        <div className="relative">
                            <button
                                className="hover:text-gray-400 flex items-center justify-center"
                                onClick={toggleCategory}>
                                Category
                                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                            </button>
                            {categoryOpen && (
                                <div className="mt-2 items-left bg-white">
                                    <Link to="/modular-kitchen" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Modular Kitchen</Link>
                                    <Link to="/Wardrobe" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wardrobe</Link>
                                    <Link to="/pooja-room" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Pooja Room</Link>
                                    <Link to="/tv-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Tv Panel</Link>
                                    <Link to="/wall-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Panel</Link>
                                    <Link to="/wall-shelves" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Shelves</Link>
                                    <Link to="/show-case" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Show Case</Link>
                                    <Link to="/false-ceiling" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>False Ceiling</Link>
                                    <Link to="/office-interior" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Office Interior</Link>
                                </div>
                            )}
                        </div>
  
                        <Link to="/testimonials" className="hover:text-gray-400" onClick={toggleMenu}>Testimonials</Link>
                        <Link to="/contact" className="hover:text-gray-400" onClick={toggleMenu}>Contact</Link>
                    </nav>
                </div>
            )}
        </header>
    );
  }
 
function ModularBanner() {
    return (
        <section className="relative bg-cover bg-center h-96 object-cover" style={{ backgroundImage: `url(${backgroundImage})` }} >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="relative container mx-auto h-full flex flex-col justify-center items-left text-left">
                <h1 className="text-white text-5xl font-bold text-left">FALSE CEILING DESIGN</h1>
                <p className="text-white mt-4">
                    <a href="/" className="hover:underline">Home</a> &gt; False Ceiling
                </p>
            </div>
        </section>
    );
}
function ModularSection() {
    return (
        <div className="container mx-auto my-16 px-4">
            {/* Full-width Heading and Subheading */}
            <div className="w-full text-center mb-10 py-5 ">
                <span className="font-sans font-semibold text-[#8c2c2c] underline underline-offset-8 ">Elegant Kitchen</span>
                <h2 className="text-4xl font-bold mt-3 mb-2">False Ceiling </h2>
                <p className="font-sans font-medium text-gray-600 mb-6 py-10">
                Enhance your interiors with our expertly crafted false ceiling designs. Custom-designed to suit your style and space, our false ceilings add a touch of elegance while also improving acoustics and lighting. Whether you prefer Modern False Ceilings, LED-Illuminated Ceilings, or Gypsum Board Designs, 'Elegant Kitchen' offers a variety of options to elevate your home.
                    </p>    </div>

            {/* Main Content Section */}
            <div className="flex flex-col lg:flex-row items-center gap-8 p-0 ">
                {/* Left Image */}
                <div className="w-full lg:w-1/2 relative">
                    {/* <hr className="absolute -top-20 left-30 w-[20%] border-t border-gray-300" /> */}

                    <img
                        src={banner8}
                        alt="Modern Building"
                        className="-mt-32 first-letter:w-full object-cover rounded-md border  p-4"
                    /><br></br><br></br>
                    <img
                        src={banner7}
                        alt="Interior Design"
                        className="w-full object-cover rounded-md  border p-4"
                    />
                    <br></br><br></br>
                    <img
                        src={banner11}
                        alt="Interior Design"
                        className="w-full object-cover rounded-md  border p-4"
                    />
                    <br></br><br></br>
                    <img
                        src={banner12}
                        alt="Interior Design"
                        className="w-full object-cover rounded-md  border p-4"
                    />
                    


                </div>

                {/* Right Content */}
                <div className="w-full lg:w-1/2">
                    {/* <h2 className="font-sans text-2xl font-semibold mb-2">Modular Kitchens Design</h2> */}
                    {/* <a
          href="#"
          className="inline-block px-8 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition duration-300 mb-8"
        >
          Read More
        </a> */}
                    {/* Bottom Image Below Button */}
                    <img
                        src={banner9}
                        alt="Interior Design"
                        className="w-full object-cover rounded-md   border p-4"
                    /><br></br><br></br>
                    <img
                        src={banner10}
                        alt="Interior Design"
                        className="w-full object-cover rounded-md  border p-4"
                    />
                    <br></br><br></br>
                    <img
                        src={banner13}
                        alt="Interior Design"
                        className="w-full object-cover rounded-md  border p-4"
                    />
                    <br></br><br></br>
                    <img
                        src={banner14}
                        alt="Interior Design"
                        className="w-full h-[60vh] object-cover rounded-md  border p-4"
                    />
                    
                    




                </div>

            </div>

        </div>
    );
};
function Footer() {
    return (
        <div>

            {/* Footer Section */}
            <footer className=" bg-black text-white">
                <div className=" pt-10 mx-10 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-8 ">
                    {/* Column 1: Logo and Description */}
                    <div>
                        <img src={logo} alt="Logo" className="h-20 lg:mr-24 md:mr-4 " />
                        <p className='pt-5 font-sans font-medium'>We welcome you to our wonderful world of Interior and Furniture.</p>
                    </div>

                    {/* Column 2: Pages Links */}
                    <div>
                        <h4 className="font-sans text-lg font-semibold mb-4">Useful Links</h4>
                        <ul>
                            <li><a href="#" className="font-sans font-normal hover:underline pb-10 ">About Us</a></li><br></br>
                            <li><a href="#" className="font-sans font-normal pb-2 hover:underline">Testimonial</a></li><br></br>
                            <li><a href="/contact" className="font-sans font-normal  pb-2 hover:underline">Contact</a></li>
                        </ul>
                    </div>

                    {/* Column 3: Office Information */}
                    <div>
                        <h4 className="text-lg font-sans font-semibold mb-4">Contact Us</h4>
                        <ul>
                            <li className="flex items-start mb-4 mt-5 ">
                                <IoLocationSharp className="text-4xl mr-4 circle" />
                                <span className='pl-2'>250/385, Padma Complex, 7th Street, Crosscut Road, Coimbatore - 641 012.</span>
                            </li>
                            <li className="flex items-center mb-4">
                                <IoCallSharp className="text-2xl mr-4" />
                                <span className='pl-2'>+91 99407 12867</span>
                            </li>
                            <li className="flex items-center">
                                <IoMailSharp className="text-2xl mr-4" />
                                <span className='pl-2'>elegantkitchen.cbe@gmail.com</span>
                            </li>
                        </ul>
                    </div>

                    {/* Column 4: Newsletter */}
                    <div>
                        <h4 className="text-lg font-sans font-semibold mb-4">Newsletter</h4>
                        <p>Contact us today for classy Design Expertise. Best Quality Solutions.</p>
                        <div className="mt-4 flex">
                            <input
                                type="email"
                                placeholder="Email here"
                                className="p-3 w-full rounded-l-md text-black border border-black outline-none"
                            />
                            <button className="bg-black text-white p-3 rounded-r-md">
                                <IoIosSend />
                            </button>
                        </div>
                    </div>
                </div >
                <div className="bg-black text-white py-4 mt-14 mx-auto text-center border-t-2 border-gray-900">
                    <p>&copy; 2024 Elegant Kitchen. All rights reserved.</p>
                </div>
            </footer>
            <FloatingWhatsApp />
        </div>
    );
}

export default FallCeiling;