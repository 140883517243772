import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import logo from './assets/logo.png';
import banner from './assets/mod1.jpg';
import banner1 from './assets/wall.webp';
import banner2 from './assets/seil.jpg';
import banner3 from './assets/lux.jpg';
import banner4 from './assets/ban7.jpg';
import banner5 from './assets/shelves6.jpg';
import banner6 from './assets/pooj.jpg';
import banner7 from './assets/testi.png';
import banner8 from './assets/seil.jpg';
import bgImage from './assets/black.jpg';
import download from './assets/download.png';
import stats from './assets/10.jpg';
import banner11 from './assets/modular15.jpg';
import banner12 from './assets/modular9.jpg';
import banner13 from './assets/pooja.jpg';
import banner14 from './assets/fall.jpg';
import banner15 from './assets/tv.jpg';
import one from './assets/ban.jpg';
import kitchen1 from './assets/modular14.png';
import kitchen2 from './assets/modular16.png';
import kitchen3 from './assets//modular11.png';
import wadd from './assets/wardrobe15.jpg';
import wadd1 from './assets/wardrobe21.jpg';
import wadd2 from './assets/wardrobe14.jpg';
import wadd3 from './assets/wardrobe13.jpg';
import wadd4 from './assets/wardrobe11.jpg';
import tvp from './assets/tvpanel115.png';
import tvp1 from './assets/tvpanel18.jpg';
import tvp2 from './assets/tvpanel17.jpg';
import tvp3 from './assets/tvpanel12.jpg';
import tvp4 from './assets/tvpanel16.png';
import poojaa from './assets/pooja1.png';
import poojaa1 from './assets/pooja2.png';
import poojaa2 from './assets/pooja3.png';
import poojaa3 from './assets/pooja4.png';
import poojaa4 from './assets/pooja5.png';
import falsee from './assets/false2.png';
import falsee1 from './assets/false3.png';
import falsee2 from './assets/false4.png';
import falsee3 from './assets/false5.png';
import falsee4 from './assets/false6.png';
import wallp from './assets/wall1.jpg';
import wallp1 from './assets/wall2.jpg';
import wallp2 from './assets/wall3.jpg';
import wallp3 from './assets/wall4.jpg';
import wallp4 from './assets/wall5.jpg';
import show from './assets/show.png';
import show1 from './assets/show1.jpg';
import show2 from './assets/show2.png';
import show3 from './assets/show3.png';
import show4 from './assets/show4.png';
import shel from './assets/shelves4.jpg';
import shel1 from './assets/shelves3.jpg';
import shel2 from './assets/shelves5.jpg';
import shel3 from './assets/shelves6.jpg';
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdOutlineStarBorderPurple500 } from "react-icons/md";
import { IoIosSend } from "react-icons/io";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Contact from './pages/contact';
import About from './pages/about';
import ModularKitchen from './pages/modular-kitchen';
import Wardrobe from './pages/wardrobe';
import PoojaRoom from './pages/pooja-room';
import TvPanel from './pages/tv-panel';
import WallPanel from './pages/wall-panel';
import WallShelves from './pages/wall-shelves';
import ShowCase from './pages/show-case.js';
import FalseCeiling from './pages/false-celling';
import OfficeInterior from './pages/office-interior';
import Testimonial from './pages/testimonials';

import bann from './assets/modular.jpg';
import bann1 from './assets/wardrobe.jpg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// import required modules
import './styles.css';

// import required modules
const contentData = [
  {

    title: "World Class Modular Kitchen Designs",
    description: "Our modular kitchen designs combine functionality with modern aesthetics, offering you a culinary space that's both stylish and efficient. Each kitchen is crafted to meet your specific needs, ensuring an organized and visually appealing environment."
  },
  {
    title: "Customized Wardrobe As Per Your Needs",
    description: "Tailored to your unique requirements, our custom wardrobes offer the perfect blend of style and practicality. From spacious layouts to smart storage solutions, our designs ensure every inch of space is utilized efficiently."
  },
  {
    title: "Elegant Kitchen Is Thinking Made Visual",
    description: "We believe in transforming ideas into reality. Our elegant kitchen designs are a testament to this philosophy, bringing together innovation and beauty to create spaces that inspire culinary creativity."
  },
  {
    title: "Best Possible Use Of Space Interior Designs",
    description: "Maximize your living space with our expertly designed interiors that make the best possible use of every square foot. Our designs focus on creating a harmonious balance between style and functionality, perfect for modern living."
  },
  {
    title: "Innovative TV Panel Designs for Modern Homes",
    description: "Experience the blend of functionality and aesthetics with our TV panel designs, tailored to complement your living space while offering practical storage solutions."
  },
  {
    title: "Pooja Room Designs That Radiate Serenity",
    description: "Create a peaceful sanctuary in your home with our pooja room designs, crafted to inspire tranquility and spiritual connection."
  },
  {
    title: "Sophisticated Wood Carving for Timeless Interiors",
    description: "Enhance the ambiance of any room with our unique false ceiling designs, offering both elegance and improved lighting solutions for a perfect finish."
  },

  // Add more content data here if necessary
];



function App() {
  return (
    <div className="px-auto min-h-screen flex flex-col">
      <Routes>
        <Route path="/" element={<MainLayout />}>
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/modular-kitchen" element={<ModularKitchen />} />
        <Route path="/wardrobe" element={<Wardrobe />} />
        <Route path="/pooja-room" element={<PoojaRoom />} />
        <Route path="/tv-panel" element={<TvPanel />} />
        <Route path="/wall-panel" element={<WallPanel />} />
        <Route path="/wall-shelves" element={<WallShelves />} />
        <Route path="/show-case" element={<ShowCase />} />
        <Route path="/false-ceiling" element={<FalseCeiling />} />
        <Route path="/office-interior" element={<OfficeInterior />} />
        <Route path="/testimonials" element={<Testimonial />} />
        {/* Add more routes if necessary */}
      </Routes>
    </div>
  );
}

function MainLayout() {
  return (
    <>
      <Header />
      <HeroSection />
      <Section1 />
      <StatsSection />
      <Section2 />
      <Project />
      <ProjectsSection />
      <Testimonials />
      <Footer />
    </>
  );
}


function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false); // For category dropdown toggle

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleCategory = () => setCategoryOpen(!categoryOpen);
  return (
    <header className=" bg-[#f8f8f8] shadow-md ">
      <div className="flex  min-md:hidden mx-auto lg:ml-24 ml-10 ">
        <div className="position: relative  z-10 mx-auto mt-5 flex w-7/12  md:p-6">
          <img src={logo} alt="Logo" className="h-16  max-sm:h-14 lg:mr-24 md:mr-4" />

          <nav className=" mx-auto font-sans font-semibold hidden md:flex space-x-8 text-gray-700 md:ml-20 lg:ml-0 ">
            <Link to="/" className="hover:text-[#8c2c2c]">Home</Link>
            <Link to="/about" className="hover:text-[#8c2c2c]">About</Link>
            <div className="relative group">
              <button className="hover:text-[#8c2c2c]">Category</button>
              <div className="absolute hidden group-hover:block bg-white shadow-lg mt-2 w-48">
                <Link to="modular-kitchen" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Modular Kitchen</Link>
                <Link to="Wardrobe" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Wardrobe</Link>
                <Link to="pooja-room" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Pooja Room</Link>
                <Link to="tv-panel" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Tv Panel</Link>
                <Link to="wall-panel" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Wall Panel</Link>
                <Link to="wall-shelves" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Wall Shelves</Link>
                <Link to="show-case" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Show Case</Link>
                <Link to="false-ceiling" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">False Ceiling</Link>
                <Link to="office-interior" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Office Interior</Link>

              </div>
            </div>
            <Link to="/testimonials" className="hover:text-[#8c2c2c]">Testimonials</Link>
            <Link to="/contact" className="hover:text-[#8c2c2c]">Contact</Link>
          </nav>
        </div>

        {/* Mobile Menu Button */}
        <div className="w-5/12 h-[100vh] md:bg-[#f8f8f8] lg:bg-black bg-[#f8f8f8] md:max-md:pl-20  max-sm:pl-20 max-sm:pt-5">
          <button
            onClick={toggleMenu}
            className="p-2 text-black lg:text-white md:text-[#f8f8f8]  lg:hidden sm:pl-30 sm:pt-10 md:pl-52 md:pt-5"
          >
            ☰
          </button>
        </div>
      </div>

      {/* Mobile Nav */}
      {menuOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black text-white z-50 flex flex-col items-center justify-center p-4 md:hidden">
          {/* Close Button */}
          <button
            onClick={toggleMenu}
            className="text-white absolute top-5 right-5 text-3xl"
          >
            ✖
          </button>
          <nav className="flex flex-col space-y-6 text-xl text-center">
            <Link to="/" className="hover:text-gray-400" onClick={toggleMenu}>Home</Link>
            <Link to="/about" className="hover:text-gray-400" onClick={toggleMenu}>About</Link>

            {/* Category Dropdown in Mobile View */}
            <div className="relative">
              <button
                className="hover:text-gray-400 flex items-center justify-center"
                onClick={toggleCategory}>
                Category
                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
              </button>
              {categoryOpen && (
                <div className="mt-2 items-left bg-white">
                  <Link to="/modular-kitchen" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Modular Kitchen</Link>
                  <Link to="/Wardrobe" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wardrobe</Link>
                  <Link to="/pooja-room" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Pooja Room</Link>
                  <Link to="/tv-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Tv Panel</Link>
                  <Link to="/wall-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Panel</Link>
                  <Link to="/wall-shelves" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Shelves</Link>
                  <Link to="/show-case" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Show Case</Link>
                  <Link to="/false-ceiling" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>False Ceiling</Link>
                  <Link to="/office-interior" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Office Interior</Link>
                </div>
              )}
            </div>

            <Link to="/testimonials" className="hover:text-gray-400" onClick={toggleMenu}>Testimonials</Link>
            <Link to="/contact" className="hover:text-gray-400" onClick={toggleMenu}>Contact</Link>
          </nav>
        </div>
      )}
    </header>

  );
}


function HeroSection() {
  // Define activeIndex and setActiveIndex
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // Auto-transition the content every 3 seconds to match the Swiper's autoplay
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % contentData.length);
    }, 3000); // 3 seconds interval
    return () => clearInterval(interval);
  }, []);
  return (
    <section className="  mx-auto max-lg:px-32 lg:px-10 pt-10 min-height lg:z-1 position: absolute w-[100%] lg:h-[100%] flex flex-col  max-sm:mt-28 ">
      {/* Left Side - Text Content */}
      <div className=" justify-center max-sm:h-[75vh] h-[100vh] mx-auto ml-auto container flex flex-col md:flex-col md:mt-36 lg:mt-0 md:w-[70vw]  lg:flex-row items-center  ">
        <div className="lg:w-1/2  md:w-[90vw] space-y-2  px-5 lg:px-0 text-left md:text-left fade-in-out sm:w-[90vw]  max-sm:mt-20  max-sm:w-[90vw]">

          <h4 className="lg:mr-20 font-sans lg:text-6xl md:text-5xl sm:text-5xl text-3xl font-bold text-gray-900 sm:top-16">
            {contentData[activeIndex].title}
          </h4>
          <p className="lg:mr-20 font-sans font-semibold text-gray-600">
            {contentData[activeIndex].description}
          </p>
          <button className="px-6 py-3 bg-black text-white font-semibold rounded hover:bg-black hover:text-white">
            Read More
          </button>
        </div>

        {/* Right Side - Bordered Image */}
        <div className="min-md:mb-50 lg:px-6 lg:w-1/2 mt-8 lg:-mr-8 md: lg:ml-20 md:mt-0 flex justify-center md:justify-end lg:py-9 lg:pl-5">
          <div className="w-[50vw] h-[70vh] md:w-[90vw]  lg:w-[50vw]  max-sm:w-[90vw] max-sm:h-[50vh] md:h-[70vh] border-4 border-white p-4 max-md:w-[90vw] ">
            <Swiper
              pagination={true}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 3000, // 3 seconds delay between slides
                disableOnInteraction: false, // Keep autoplay running even after user interaction
              }}
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)} // Update activeIndex on slide change
              className="mySwiper"
            >
              <SwiperSlide><img src={banner7} alt="Interior Design" className="w-full lg:h-[70vh]" /></SwiperSlide>
              <SwiperSlide><img src={banner1} alt="Interior Design" className="w-full lg:h-[70vh]" /></SwiperSlide>
              <SwiperSlide><img src={banner2} alt="Interior Design" className="w-full lg:h-[70vh]" /></SwiperSlide>
              <SwiperSlide><img src={banner3} alt="Interior Design" className="w-full lg:h-[70vh]" /></SwiperSlide>
              <SwiperSlide><img src={banner4} alt="Interior Design" className="w-full lg:h-[70vh]" /></SwiperSlide>
              <SwiperSlide><img src={banner5} alt="Interior Design" className="w-full lg:h-[70vh]" /></SwiperSlide>
              <SwiperSlide><img src={banner6} alt="Interior Design" className="w-full lg:h-[70vh]" /></SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section1() {
  return (
    <section className="max-md:pt-40  md:pt-48 lg:pt-0 max-sm:pt-10  bg-[#f8f8f8] px-6   lg:px-16 ">
      <div className="container mx-auto mt-20 mb-20 flex flex-col md:flex-row items-center">
        {/* Left Side - Image */}
        <div className="w-full md:w-6/12 flex justify-center md:justify-start">
          <img
            src={banner}
            alt="Banner"
            className="w-full h-auto md:w-[50vw] md:h-[80vh] max-sm:w-[100vw] object-cover border-4 border-white p-4"
          />
        </div>

        {/* Right Side - Content */}
        <div className="w-full md:w-1/2 flex flex-col justify-center items-start mt-5 md:mt-0 md:ml-10 space-y-4">
          <span className="text-black font-sans font-semibold text-4xl lg:text-9xl md:text-5xl opacity-10">
            About us
          </span>
          <h5 className="text-2xl md:text-3xl font-semibold text-gray-900">
            Welcome To <span className="text-[#8c2c2c]">Elegant Kitchen!</span>
          </h5>
          <p className="font-sans text-gray-600">
            We welcome you to our wonderful world of Interior and Furniture. We
            are one of the leading crafters of quality modular kitchens since
            2009. We have a well-maintained state-of-the-art infrastructure
            with modern machinery and a well-trained workforce to fulfill the
            needs of our customers. We have the ability to offer the solution of
            Designing, Manufacturing, and Facility Management Services
            according to your favorable choice. We are Elegant Kitchen. You
            dream, we make it real for you. We specialize in Modular Kitchens,
            Wardrobes, Fall Ceiling, Pooja Design, Wood Carving, and TV
            Showcases.
          </p>
          <button className="px-6 py-3 bg-black text-white font-semibold rounded hover:bg-black">
            Know More
          </button>
        </div>
      </div>
    </section>
  );
}

function StatsSection() {
  return (
    <section className="relative w-full h-auto flex flex-col justify-center items-center py-16 mx-0">

      {/* Heading Section */}
      <div className="w-full mb-8 text-center">
        <h2 className="text-4xl md:text-5xl lg:text-5xl font-bold text-gray-900 font-sans pb-2">
          Where Your <span className="bg-black p-2 rounded-full animate-pulse">✨</span>  Dream <br></br>Space Comes to Life
        </h2>
      </div>

      {/* Main Image (Centered) */}
      <div className="relative w-full max-w-5xl">
        <img
          src={stats} // Replace with your image path
          alt="Living Space"
          className="w-full h-auto object-cover mx-auto rounded-lg"
        />

        {/* Left Box (Horizontal Move) */}
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-20 transition-transform duration-500 hover:translate-x-0">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-2xl font-semibold text-gray-800">100+</h3>
            <p className="text-sm text-gray-600">Complete project</p>
          </div>
        </div>

        {/* Right Box (Vertical Move) */}
        <div className="absolute top-1/2 right-0 transform -translate-y-1/2 translate-y-20 transition-transform duration-500 hover:-translate-y-0">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">

            <p className=" text-gray-800 font-sans font-semibold text-1xl">Crafting Your Dream Interiors with <br></br>Elegance and Precision Since 2009</p>

          </div>
        </div>
      </div>
    </section>
  );
}

function Section2() {
  const features = [
    {
      title: "Understand what you need",
      description:
        "We understand you and the requirements of your living space. Our technicians work with you to look for the precise balance between aesthetics and functionality ensuring budget, time and quality goals are as per expectations.",
    },
    {
      title: "Precise Engineering",
      description:
        "We practice strong expertise and advanced equipment in modular kitchen & Interior designing.",
    },
    {
      title: "Designs for all tastes and styles",
      description:
        "Our designs cater to diverse tastes and styles, ensuring everyone finds something they love.",
    },
    {
      title: "Highest Performance",
      description:
        "We commit to delivering timeless designs, innovative ideas, and superior craftsmanship for every project.",
    },
  ];

  return (
    <section className="relative text-white py-20 lg:pt-30" style={{ backgroundColor: 'black' }}>
      <div
        className="absolute inset-0 bg-cover bg-center z-0 opacity-40 max-xl:h-[69vh] lg:h-[67vh] sm:h-[87vh] md:h-[80vh] max-md:h-[95vh]  max-sm:h-[91vh] min-sm:h-[111vh]"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <div className="relative z-10">
        {/* Your content goes here */}
      </div>
      <div className="px-5 container mx-auto text-center py-10">
        <h2 className="text-4xl font-semibold mb-6 underline underline-offset-8  ">WHY ELEGANT KITCHEN?</h2>

        <p className="text-lg text-justify">
          We understand your needs and the unique requirements of your living space. Our technicians collaborate closely with you to achieve the perfect balance between aesthetics and functionality, ensuring that your budget, timeline, and quality expectations are met. With a wide range of designs tailored to all tastes and styles, we offer customization to fit your specific preferences. Our precise engineering, combined with advanced equipment and strong expertise in modular kitchen and interior design, guarantees the highest performance. We are committed to delivering timeless designs, innovative ideas, and expert craftsmanship, ensuring top-quality products and services. Transparency is at the core of our approach, as we provide clear and accurate details in all our dealings.</p>
      </div>
    </section>
  );
}
function Project() {
  return (
    <div className=" mx-auto my-16 px-4">
      {/* Heading Section */}
      <div className="w-full text-center mb-10">
        <span className="font-sans font-semibold text-[#8c2c2c] underline underline-offset-8 ">Elegant Kitchen</span>
        <h2 className="text-4xl font-bold mt-3 mb-2">Few Of Our Crafts Made With Love</h2>
        {/* <p className="text-gray-600">
          Nulla vitae ex nunc. Morbi quis purus convallis, fermentum hioon metus
          volutpat design sodales purus. Nunc quis an mauris etion eros
          vulputate mattis Nulla vitae ex nunc.
        </p> */}
      </div>

      {/* Image Section */}
      <div className="flex flex-col lg:flex-row gap-8">
        {/* First Image with Hover Effect */}
        <div className="relative group w-full lg:w-1/2">
          <img
            src={bann}
            alt="Kitchen Design"
            className="w-full h-full object-cover "
          />
          <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 flex items-end p-4  transition-opacity duration-300">
            <div>
              <a href='./modular-kitchen'>
                <h4 className="text-white text-xl font-bold mb-1">
                  Modular Kitchens Design
                </h4></a>
              <hr className="border-yellow-500 w-20 mb-2" />
              <p className="text-white">We understand how much time you spend in your kitchen every day, and we understand your need for a kitchen that understands you…</p>
            </div>
          </div>
        </div>

        {/* Second Image with Hover Effect */}
        <div className="relative group w-full lg:w-1/2">
          <img
            src={bann1}
            alt="Modern Building"
            className="w-full h-full object-cover "
          />
          <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 flex items-end p-4  transition-opacity duration-300">
            <div>
              <a href='./wardrobe'>
                <h4 className="text-white text-xl font-bold mb-1">
                  Wardrobe Design
                </h4>
              </a>
              <hr className="border-yellow-500 w-20 mb-2" />
              <p className="text-white">We have you covered with our limitless wardrobe interior design, whether you select a sliding modular wardrobe, hinged high gloss…</p>
            </div>
          </div>
        </div>


      </div>

      {/* Image Section */}
      <div className="flex flex-col lg:flex-row gap-8 py-10">
        {/* First Image with Hover Effect */}
        <div className="relative group w-full lg:w-6/12">
          <img
            src={banner13}
            alt="Kitchen Design"
            className="w-full h-[50vh] object-cover "
          />
          <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 flex items-end p-4  transition-opacity duration-300">
            <div>
              <a href='./pooja-room'>
                <h4 className="text-white text-xl font-bold mb-1">
                  Pooja Room Design
                </h4>
              </a>
              <hr className="border-yellow-500 w-20 mb-2" />
              <p className="text-white">A pooja room serves as a haven for tranquilly and prayer, and we are experts at incorporating these qualities into our pooja room designs…</p>
            </div>
          </div>
        </div>

        {/* Second Image with Hover Effect */}
        <div className="relative group w-full lg:w-3/12 ">
          <img
            src={banner14}
            alt="Modern Building"
            className="w-full h-[50vh] object-cover "
          />
          <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 flex items-end p-4  transition-opacity duration-300">
            <div>
              <a href='./false-ceiling'>
                <h4 className="text-white text-xl font-bold mb-1">
                  False Ceiling Design
                </h4>
              </a>
              <hr className="border-yellow-500 w-20 mb-2" />
              <p className="text-white">Elegant Kitchen offers a modern and hi-tech best False Ceiling in Coimbatore. We provide false ceilings in various types… </p>
            </div>
          </div>
        </div>
        <div className="relative group w-full lg:w-3/12">
          <img
            src={banner15}
            alt="Kitchen Design"
            className="w-full h-[50vh] object-cover "
          />
          <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 flex items-end p-4  transition-opacity duration-300">
            <div>
              <a href='./'>
                <h4 className="text-white text-xl font-bold mb-1">
                  TV panel Design
                </h4>
              </a>
              <hr className="border-yellow-500 w-20 mb-2" />
              <p className="text-white">Add a stylish tv panel to your living room. Customized perfectly matching panels for your TV, LED and LCDs…. </p>
            </div>
          </div>
        </div>


      </div>

    </div>

  );
};




function ProjectsSection() {
  const [filter, setFilter] = useState('Modular Kitchen');
  const [filter1, setFilter1] = useState('Wardrobe');
  const [filter2, setFilter2] = useState('TV Panel design');

  // Sample images for each category
  const images = {
    'Modular Kitchen': [
      { id: 1, src: kitchen3, alt: 'Kitchen Project 1', className: 'col-span-2' },
      { id: 2, src: kitchen1, alt: 'Project 2' },
      { id: 3, src: kitchen2, alt: 'Project 3' },
      { id: 4, src: banner11, alt: 'Project 4' },
      { id: 5, src: banner12, alt: 'Project 5' },

    ],
    'Wardrobe': [
      { id: 1, src: wadd, alt: 'Exterior 1' },
      { id: 2, src: wadd1, alt: 'Exterior 2' },
      { id: 3, src: wadd2, alt: 'Exterior 2' },
      { id: 4, src: wadd3, alt: 'Exterior 2' },
      { id: 5, src: wadd4, alt: 'Exterior 2' },
    ],
    'TV Panel design': [
      { id: 1, src: tvp, alt: 'Project 1' },
      { id: 2, src: tvp1, alt: 'Project 2' },
      { id: 3, src: tvp2, alt: 'Project 3' },
      { id: 4, src: tvp3, alt: 'Project 4' },
      { id: 5, src: tvp4, alt: 'Project 3' },

    ],
    'Pooja Design': [
      { id: 1, src: poojaa, alt: 'Worldwide 1' },
      { id: 2, src: poojaa1, alt: 'Worldwide 2' },
      { id: 3, src: poojaa2, alt: 'Worldwide 2' },
      { id: 4, src: poojaa3, alt: 'Worldwide 2' },
      { id: 5, src: poojaa4, alt: 'Worldwide 2' },
    ],
    'False Ceiling': [
      { id: 1, src: falsee, alt: 'Countrylife 1' },
      { id: 2, src: falsee1, alt: 'Countrylife 2' },
      { id: 3, src: falsee2, alt: 'Countrylife 2' },
      { id: 4, src: falsee3, alt: 'Countrylife 2' },
      { id: 5, src: falsee4, alt: 'Countrylife 2' },
    ],
    'Wall Panel Design': [
      { id: 1, src: wallp, alt: 'Countrylife 1' },
      { id: 2, src: wallp1, alt: 'Countrylife 2' },
      { id: 3, src: wallp2, alt: 'Countrylife 2' },
      { id: 4, src: wallp3, alt: 'Countrylife 2' },
      { id: 5, src: wallp4, alt: 'Countrylife 2' },
    ],
    'Showcase Design': [
      { id: 1, src: show, alt: 'Countrylife 1' },
      { id: 2, src: show1, alt: 'Countrylife 1' },
      { id: 3, src: show2, alt: 'Countrylife 1' },
      { id: 4, src: show3, alt: 'Countrylife 1' },
      { id: 5, src: show4, alt: 'Countrylife 1' },
    ],
    'Wall Shelves Design': [
      { id: 1, src: shel, alt: 'Countrylife 1' },
      { id: 2, src: shel1, alt: 'Countrylife 2' },
      { id: 3, src: shel2, alt: 'Countrylife 2' },
      { id: 4, src: shel3, alt: 'Countrylife 2' },

    ]

  };

  return (
    <section className=" bg-white py-10">
      <div className="container mx-auto px-4">
        <div className=''>
          {/* Heading */}
          <div className="text-center mb-10">
            <h2 className="text-4xl font-bold text-gray-900 space-x-3">Where Form Meets Function <br />with Flair Designer</h2>
          </div>

          {/* Filter Menu */}
          <div className="grid grid-cols-1 sm:grid-cols-5 max-sm:grid-cols-3 lg:grid-cols-5 lg:px-5 gap-4 mb-12">
            {['Modular Kitchen', 'Wardrobe', 'TV Panel design', 'Pooja Design', 'False Ceiling', 'Wall Panel Design', 'Showcase Design', 'Wall Shelves Design'].map((category) => (
              <button
                key={category}
                className={`px-6 py-2 border rounded-lg font-sans font-semibold ${filter === category ? 'bg-black text-white' : 'rounded-lg border-gray-300 text-gray-700 hover:text-white hover:bg-[#8c2c2c]'} transition`}
                onClick={() => setFilter(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className='m-0 p-0 mas-sm:px-10'>
        {/* Projects Slider */}
        <Swiper
          pagination={true}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 3000, // 3 seconds delay between slides
            disableOnInteraction: false, // Keep autoplay running even after user interaction
          }}
          spaceBetween={15}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          className="pt-10"

        >

          {images[filter].map((image) => (
            <SwiperSlide key={image.id}>
              <div className="hover:scale-105 hover:-translate transition-transform duration-300 hover:space-x-2 h-[80vh]">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full object-cover border-1 p-0 border-white"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>


    </section>
  );
}
function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      name: "Dr. Rekha Manikandan and family",
      // position: "Marketing Coordinator",
      description:
        "First of all we want to wholeheartedly thank u and ur team for your wonderful interior work done in our house. As per ur company name stands “ELEGANT ” Elegancy is seen in each and every part of ur work. Without your teams diligence, hardworking, late nights and early mornings, we wouldn’t have been able to shift as per our scheduled date. Excellent work. Keep it up.We wish u all success in all ur future endeavors 👏👏👏",
      image: "path_to_your_image", // Replace with the actual image path or URL
    },
    {
      name: "Prabu",
      position: "Jakkarpalayam",
      description:
        "I am incredibly pleased with Interior Designs of kitchen and bedroom from elegant kitchen. I would definitely recommend them as they have verygood design concepts and variety of options. Fantastic service, delivery and setup was on time and well planned, which was great.",
      image: "path_to_your_image", // Replace with the actual image path or URL
    },
    {
      name: " Anantha subramaniam,",
      position: " IT Professional, Coimbatore",
      description:
        "Janarthanan sir provided us with amazing tips and ideas for every room in our home, including window treatments. He and his team members closely worked with us and helped in giving a fresh look to our home. We highly recommend them if you are looking for an affordable Interior Designer in Coimbatore . We truly enjoyed working with them . He understands your requirements & budget good & comes up with innovative ideas. He tracks the progress& makes sure everything is delivered as promised.What I like the most is that they Worked for us very passionately and mostly committed to deliver it",
      image: "path_to_your_image", // Replace with the actual image path or URL
    },
    {
      name: "Arthi S,",
      position: "Coimbatore",
      description:
        "The best interior team I ever came across.Janarthanan sir did interior for our first lovable home. He came out with new designs as per our requirement and taste. He even helped to visualise the same with 3D view and produced the output exactly same accurate way. The entire team is professional and did everything as per planned timeline. He considered our project as like his home and did the best. Also provided lot of contact and support in civil area to complete our house.Excellent work within budget and more friendly..I strongly recommend them!!",
      image: "path_to_your_image", // Replace with the actual image path or URL
    },
    // Add more testimonials as needed
  ];

  const handlePrev = () => {
    setCurrentIndex(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1);
  };

  return (
    <div className="relative pt-10 h-auto flex-wrap items-left  bg-black text-white">
      {/* Full-width section with heading "TESTIMONIALS" */}
      <div className='max-w-6/12 mx-auto flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6'>
        <div className=" text-left mx-8 w-full md:w-1/2  md:text-left">
          <h1 className="font-sans text-1xl font-bold  md:text-1xl text-[#f3efef]">TESTIMONIALS</h1>
          <h2 className="font-sans text-4xl font-bold md:text-4xl text-white text-left py-5">
            Discover the Art of <br className="hidden md:block"></br>Interior Transformation
          </h2>

        </div>
        <div className='w-6/12 md:w-1/2'>
          <span className='text-white font-sans font-semibold text-9xl opacity-10 max-md:text-6xl '>
            Review
          </span>

        </div>
      </div>




      {/* Split layout for image and testimonials carousel */}
      <div className="bg-black max-w-6/12 md:p-6 mx-auto shadow-lg rounded-lg flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6 text-white">
        {/* Left side - Image */}
        <div className="w-1/2 max-md:w-1/2">
          <img
            src={one}
            alt="Testimonial"
            className="w-full h-auto rounded-lg"
          />
        </div>

        {/* Right side - Testimonials carousel */}
        <div className="w-1/2 max-md:w-1/2 max-md:text-left">
          {/* Star rating */}
          <div className="flex justify-center md:justify-start items-center mb-4">
            <div className="flex text-white ">
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
            </div>
          </div>

          {/* Testimonial Details */}
          <h3 className="text-xl max-md:text-xl font-semibold text-white">
            {testimonials[currentIndex].name}
          </h3>
          <p className="text-sm md:text-base text-white">
            {testimonials[currentIndex].position}
          </p>
          <hr className="my-2 border-white" />
          <p className="mt-4 text-white md:text-base">
            {testimonials[currentIndex].description}
          </p>

          {/* Carousel navigation */}
          <div className="flex justify-center items-center mt-6 space-x-4">
            <button
              onClick={handlePrev}
              className="text-white p-4  hover:bg-gray-100 hover:text-black"
            >
              &larr;
            </button>
            <button
              onClick={handleNext}
              className="text-white p-4  hover:bg-gray-100 hover:text-black"
            >
              &rarr;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div>
      {/* Subscribe Newsletter Section */}
      {/* <section className="bg-black py-16 text-center">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6">
            {/* <div className="w-full md:w-1/2 text-center md:text-left">
              <h2 className="text-white text-3xl md:text-4xl font-bold mb-6 md:mb-0 ">

                SUBSCRIBE FOR<br className="hidden md:block" />UPDATES
              </h2>
            </div> *
            <div className="w-full md:w-1/2 flex justify-center md:justify-start items-center">
              <div className="flex flex-col md:flex-row w-full md:w-auto space-y-4 md:space-y-0 md:space-x-4">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  className="w-full md:w-64 p-3 border-none outline-none rounded-md"
                />
                <button className="bg-black text-white py-3 px-6 rounded-md font-semibold">
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Footer Section */}
      <footer className=" bg-white text-black">
        <div className=" pt-10 mx-10 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-8 ">
          {/* Column 1: Logo and Description */}
          <div>
            <img src={logo} alt="Logo" className="h-20 lg:mr-24 md:mr-4 " />
            <p className='pt-5 font-sans font-medium'>We welcome you to our wonderful world of Interior and Furniture.</p>
          </div>

          {/* Column 2: Pages Links */}
          <div>
            <h4 className="font-sans text-lg font-semibold mb-4">Useful Links</h4>
            <ul>
              <li><a href="/about" className="font-sans font-normal hover:underline pb-10 ">About Us</a></li><br></br>
              <li><a href="/testimonials" className="font-sans font-normal pb-2 hover:underline">Testimonial</a></li><br></br>
              <li><a href="/contact" className="font-sans font-normal  pb-2 hover:underline">Contact</a></li>
            </ul>
          </div>

          {/* Column 3: Office Information */}
          <div>
            <h4 className="text-lg font-sans font-semibold mb-4">Contact Us</h4>
            <ul>
              <li className="flex items-start mb-4 mt-5 ">
                <IoLocationSharp className="text-4xl mr-4 circle" />
                <span className=''>250/385, Padma Complex, 7th Street, Crosscut Road, Coimbatore - 641 012.</span>
              </li>
              <li className="flex items-center mb-4">
                <FaPhoneAlt className="text-1xl mr-4 circle" />
                <span className=''>+91 99407 12867</span>
              </li>
              <li className="flex items-center">
                <MdEmail className="text-1xl mr-4 circle" />
                <span className=''>elegantkitchen.cbe@gmail.com</span>
              </li>
            </ul>
          </div>

          {/* Column 4: Newsletter */}
          <div>
            <h4 className="text-lg font-sans font-semibold mb-4">Newsletter</h4>
            <p>Contact us today for classy Design Expertise. Best Quality Solutions.</p>
            <div className="mt-4 flex">
              <input
                type="email"
                placeholder="Email here"
                className="p-3 w-full rounded-l-md text-black border border-black outline-none"
              />
              <button className="bg-black text-white p-3 rounded-r-md">
                <IoIosSend />
              </button>
            </div>
          </div>
        </div>
        <div className="bg-black text-white py-4 mt-10 mx-auto text-center">
          <p>&copy; 2024 Elegant Kitchen. All rights reserved.</p>
        </div>
      </footer>
      <FloatingWhatsApp />
    </div>
  );
}


export default App;
